import { Outlet, Link } from "react-router-dom";
import logo from "../assets/img/quizini-logo.png";

export default function EmptyCard() {
  return (
    <>
      <main className="bg-gray-100">
        <div className="min-height-full min-h-screen flex flex-col py-8 justify-center sm:px-2 lg:px-4">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <div className="mx-auto w-auto flex items-center self-center flex-col">
                  <Link to="/">
                      <img className="h-12 w-auto" src={logo} alt="Quizini Logo" />
                  </Link>
              </div>
          </div>
          <div className="sm:max-w-lg mt-10 sm:mx-auto sm:w-full px-4 sm:px-6">
              <div className="bg-white py-8 px-4 shadow sm:rounded-lg px-6 sm:px-10">
                  <Outlet />
              </div>
          </div>
        </div>
      </main>
    </>
  );
}
