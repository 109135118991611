// import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AuthProvider } from "utils/AuthProvider";
import { ModalProvider } from "utils/ModalProvider";
import { ProgressProvider } from "utils/ProgressProvider";
import { ReportProvider } from "utils/ReportProvider";
import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";
import TagManager from 'react-gtm-module'
import { Toaster } from 'sonner'
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import App from "./App";
import "./assets/styles/tailwind.css";
import 'swiper/css';

// import { StrictMode } from "react";
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

function enableAnalytics() {
  // posthog.init('phc_bzMTJgHFKnTP6LSwbmBTrWfJgjabozizOk5hviY7WkE', { api_host: 'https://eu.posthog.com' })
  const cookieConsentValue = getCookieConsentValue()
  if(cookieConsentValue === 'true') {
    const tagManagerArgs = {
      gtmId: 'GTM-KNCJ6DH'
    }
    TagManager.initialize(tagManagerArgs);
  }
}

enableAnalytics();

const queryClient = new QueryClient()

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  // <StrictMode>
  <div className="lg:bg-zinc-100">
    <Helmet>
        <meta charSet="utf-8" />
        <title>Quizini | Play, Create and Share Personality and Trivia Quizzes</title>
        <meta
          name="description"
          content="Quizini the quiz app where you can create and take personality quizzes online."
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:domain" content="www.quizini.com" />
        <meta name="twitter:title" property="og:title" itemprop="name" content="Quizini | Play, Create and Share Personality and Trivia Quizzes" />
        <meta name="twitter:description" property="og:description" itemprop="description" content="Quizini | The Quiz app for personality and trivia Quizzes"></meta>
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Quizini" />
        <meta property="og:description" content="Quizini the quiz app where you can create and take personality quizzes online." />
    </Helmet>
    <Toaster
      position="bottom-center"
      toastOptions={{
        className: 'mb-8 sm:mb-0',
      }}
      richColors={true}
    />
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthProvider>
          <ProgressProvider>
            <ModalProvider>
              <ReportProvider>
                <Routes>
                  <Route path="/*" element={<App />} />
                </Routes>
              </ReportProvider>
            </ModalProvider>
          </ProgressProvider>
        </AuthProvider>
      </BrowserRouter>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
    <CookieConsent
      location="bottom"
      buttonText="I Agree"
      declineButtonText="Decline"
      enableDeclineButton
      onAccept={() => enableAnalytics()}
      style={{ background: "#fff", color: '#334155'}}
      buttonStyle={{ background: "#7E3EB0", color: "#fff", fontSize: "14px", borderRadius: "1000px", padding: "5px 20px" }}
      declineButtonStyle={{ background: "#7E3EB0", color: "#fff", fontSize: "14px", borderRadius: "1000px", padding: "5px 20px" }}
      expires={150}
    >
      Quizini uses cookies to enhance the user experience.
    </CookieConsent>
  </div>
  // </StrictMode>
);
