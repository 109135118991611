import UserDisplay from "components/User/UserDisplay";
import { useEffect, useState } from "react";
import useAxiosPrivate from "utils/hooks/useAxiosPrivate";
import useAuth from "utils/hooks/useAuth";

export default function SimilarUsers({}) {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [similarUsers, setSimilarUsers] = useState([]); // [User

  const getSimilarUsers = async () => {
    axiosPrivate.post('/api/users/similar', {
      interests: auth?.user?.interests,
    }).then(function (res) {
      if (res.data != null && res.data.success) {
        setSimilarUsers(res.data.users);
      } else {
        console.log('error fetching similar users');
      }
    });
  };
  
  useEffect(() => {
    if(auth?.user){
      getSimilarUsers();
    }
  }, []);
  
  return (
    <div className="">
      {similarUsers.map((user, index) => (
        <div className="mb-2 hover:bg-gray-100 rounded-lg" key={index}>
          <UserDisplay user={user} type="username" />
        </div>
      ))}
    </div>
  );
}
