import { Outlet } from "react-router-dom";

// components
import Footer from "components/Footer/Footer.js";
import NewNewNavbar from "components/Navbar/NewNewNavbar";
import { Container } from "components/Container/Container";
import SidebarRight from "views/SidebarRight/Sidebar";

export default function SidebarLayout() {
  return (
    <>
      <NewNewNavbar>
        <div className="flex">
          <div className="max-w-3xl mx-auto w-full">
            <Outlet />
            <Footer />
          </div>
          <SidebarRight
            tags={[]}
          />
        </div>
      </NewNewNavbar>
    </>
  );
}
