import { clsx } from 'clsx'
import { Button } from "components/button";
import { useLocation, useNavigate } from 'react-router-dom';
import { createPortal } from 'react-dom';
import useAuth from 'utils/hooks/useAuth';
import { Navbar, NavbarItem, NavbarSection, NavbarSpacer } from './navbar';
import { Link } from 'react-router-dom';
import Logo from "assets/img/Quizini-Logo.svg";
import { Container } from './Container/Container';
import ProgressAvatar from './Avatar/ProgressAvatar';

export function PageHeader({ children, title, setShowSidebar, navbar, className, hasBackButton = true, isSticky=true, ...props }) {
  const location = useLocation();
  const { auth } = useAuth();

  const navbarTitle = document.getElementById('navbar-title');
  const stickyClass = isSticky ? "sticky top-0 bg-white" : "";

  let isMobileHeaderShownVar = false; //isMobileHeaderShown({location});

  // Hide header if location is /messages/..
  if (location.pathname.includes('/messages/')) {
    return null;
  }

  if(!auth?.user){
    return (<PageHeaderSignedOut />);
  }

  return (
    <div className={clsx(
      className,
      stickyClass,
      "border-b border-gray-200 lg:hidden max-w-7xl mx-auto flex w-full flex-nowrap items-center justify-between p-1 z-10"
    )}>
      <div
        className={clsx(
          "flex items-center overflow-hidden justify-between w-full",
        )}
      >
        <NavbarItem onClick={() => setShowSidebar(true)} aria-label="Open navigation">
          <ProgressAvatar
            direction="left"
            showPoints={false}
          />
        </NavbarItem>
        <div className="min-w-0 flex-1">{navbar}</div>
        <div className='w-14'>
        </div>
        {(isMobileHeaderShownVar && navbarTitle) && (
          createPortal(
            <>
                {title}
            </>,
            navbarTitle
          )
        )}
      </div>
      <div className="flex items-center gap-4">
        {children}
      </div>
    </div>
  )
}

export function PageHeaderSignedOut({className, ...props}){
  return (
    <Container
      className={clsx(
        "sticky top-0 bg-white isolate z-10 lg:hidden",
        className
      )}
    >
      <Navbar>
        <Link className='block lg:hidden' to="/" aria-label="Home">
          <img
            className="h-8 w-auto"
            src={Logo}
            alt="Your Company"
          />
        </Link>
        <NavbarSpacer />
        <NavbarSection>
          <Button 
            href="/login"
            plain
            className="whitespace-nowrap"
          >
            Log in
          </Button>
          <Button 
            href="/register"
            color="quizini"
            className="px-[20px] sm:px-[20px] whitespace-nowrap"
          >
            Sign up
          </Button>
        </NavbarSection>
      </Navbar>
    </Container>
  )
}


export function SecondaryHeader({children, className}){
  return(
    <div className={clsx("flex justify-between mt-0 sticky top-0 bg-white z-10 h-16 items-center px-4 sm:px-8", className)}>
      {children}
    </div>
  );
}