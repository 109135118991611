import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

export default function Alert({text, title, link, linkText, children}) {
  return (
    <div className="rounded-md bg-purple-50 p-4 w-full">
      <div className="flex justify-between">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon
                className="h-5 w-5 text-purple-700"
                aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            {title && (
              <h3 className="text-sm font-bold text-gray-800">{title}</h3>
            )}
            {text && (
              <div 
                className={"text-sm text-gray-800 "+(title ? "mt-2" : "")}
              >
                <p dangerouslySetInnerHTML={{ __html: text }}>
                </p>
              </div>
            )}
            {/* {children} */}
          </div>
        </div>
        {link && linkText && (
          <p className="mt-3 text-sm md:ml-6 md:mt-0">
            <Link to={link} className="whitespace-nowrap font-medium text-purple-800 hover:text-gray-600">
              {linkText}
              <span aria-hidden="true"> &rarr;</span>
            </Link>
          </p>
        )}
      </div>
    </div>
  )
}