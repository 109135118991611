import { Fragment } from 'react';
// import { Dialog, Transition } from '@headlessui/react';
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from 'components/dialog'

export default function Modal({ open, setOpen, title, children }) {

    function closeModal() {
      setOpen(false);
    }

    return (
      <>
      <Dialog open={open} onClose={setOpen}>
        {title && (
          <DialogTitle>{title}</DialogTitle>
        )}
        <DialogBody>
          {children}
        </DialogBody>
      </Dialog>
      {/* <Transition.Root show={open} as={Fragment}>
        {open && (
          <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={closeModal}></div>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-start justify-center min-h-screen pt-16 px-4 pb-20 text-center sm:block sm:p-0">
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 sm:align-middle sm:max-w-lg w-full">
                  <div className="bg-white p-4">
                    {title && (
                      <h2
                        className="text-xl font-semibold text-gray-700 mb-4"
                      >
                        {title}
                      </h2>
                    )}
                    {children}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
        )}
      </Transition.Root> */}
      </>
    );
  }