import { Outlet } from "react-router-dom";
import NewNewNavbar from "components/Navbar/NewNewNavbar";

export default function NoFooter() {
  return (
    <NewNewNavbar>
        <Outlet />
    </NewNewNavbar>
  );
}
