import { 
  EyeIcon,
  HeartIcon,
} from '@heroicons/react/20/solid';
import { storyCategoryList } from "utils/data/StoryCategories.js";

export default function StoryStats({ story }) {
  return (
    <div className="flex items-center">
      <StoryCategory category={story.category} />
      <div className="ml-6 flex text-sm text-gray-700 items-center">
        <EyeIcon className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
        {story.readCount ? story.readCount : 1}
      </div>
      <div className="ml-6 flex text-sm text-gray-700 items-center">
        <HeartIcon className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
        {story.likeCount ? story.likeCount : 0}
      </div>
    </div>
  );
}

export function StoryCategory({category}){
  const foundCategory = storyCategoryList.find((cat) => cat.id === category);

  return (
    <span className="inline-flex items-center rounded-md bg-purple-50 px-2 py-0.5 text-xs text-purple-700 ring-1 ring-inset ring-purple-700/10">
      {foundCategory ? foundCategory.label : "No Category"}
    </span>
  );
}