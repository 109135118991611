import { Link } from 'react-router-dom';
// import QuizTypeBadge from "../Label/QuizTypeLabel";
import Image from 'components/Image/Image';
import { StarIcon, EyeIcon } from '@heroicons/react/20/solid';
import { PlayIcon } from '@heroicons/react/24/outline';
import FullNameAvatar from 'components/Avatar/FullNameAvatar';
import { Fragment, useState } from 'react';
import { Dialog, RadioGroup, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import StoryStats, { StoryCategory } from 'views/Story/view/StoryStats';

export default function StoryCard({ story, ...props }) {
  function convertNumber(num) {
    if (num < 1000) {
      return num.toString();
    } else if (num < 1000000) {
      return (num / 1000).toFixed(1) + ' K';
    } else {
      return (num / 1000000).toFixed(1) + ' M';
    }
  }

  return (
    <Link
      to={'/story/' + story?._id + '_' + story.id}
      className="relative w-full group "
    >
      <div className="grid w-full items-start gap-x-6 gap-y-8 grid-cols-12 bg-gray-50 rounded-md relative">
        <div className="aspect-h-3 aspect-w-2 overflow-hidden rounded-lg bg-gray-50 col-span-4 sm:col-span-5">
          <Image
            className="h-full aspect-[1/1.5] object-cover object-center rounded-lg max-h-40 sm:max-h-72 mx-auto shadow-md"
            containerClassName="w-full h-full"
            src={story?.img ? story.img.url : ''}
            alt=""
          />
        </div>
        <div className="col-span-7 py-4 flex flex-col justify-between h-full">
          <div>
            <h2 className="text-lg font-bold text-gray-900 leading-6">
              {story?.title}
            </h2>
            <div className="text-gray-500 text-xs mt-1">
              by {story.author.name ? story.author.name : story.author.username}
            </div>
            {/* <div className="text-gray-500 text-sm mt-1 overflow-hidden">
              {story.description ? story.description.substring(0, 100)+"..." : ""}
            </div> */}
          </div>
          <section className="mt-1">
            <StoryStats story={story} />
          </section>
          {/* <FullNameAvatar author={story?.author} /> */}
        </div>
      </div>
    </Link>
  );
}

export function StoryCardSmall({story}) {
  return (
    <Link
      to={'/story/' + story?._id + '_' + story.id}
      className="relative w-full group "
    >
      <div className="relative w-36 sm:w-44">
        <div className="relative">
          <section className="absolute group-hover:opacity-80 opacity-30 top-1 right-3 mb-1">
            <StoryCategory category={story.category} />
          </section>
          <Image
            className="aspect-[1/1.5] object-cover object-center rounded-lg max-h-60 sm:max-h-64 shadow-lg"
            src={story?.img ? story.img.url : ''}
            alt=""
          />
        </div>
        <div className="pt-2 px-1 flex flex-col h-full">
          <div>
            <h2 className="text-sm sm:text-md font-medium text-gray-900 leading-4 line-clamp-2 h-8">
              {story?.title}
            </h2>
            <div className="text-gray-500 text-xs mt-1">
              {story.author.name ? story.author.name : story.author.username}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}
