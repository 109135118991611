import axios from 'axios';

let BASE_URL = 'https://api.quizini.com/';

if (process.env.NODE_ENV !== 'production') {
  console.log("DEV MODE");
  BASE_URL = 'http://localhost:5100/';
}

export default axios.create({
    baseURL: BASE_URL
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});