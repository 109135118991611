
export const storyCategoryList = [
  {
    id: 'action',
    label: 'Action',
  },
  {
    id: 'fanfiction',
    label: 'Fanfiction',
  },
  {
    id: 'fantasy',
    label: 'Fantasy',
  },
  {
    id: 'comedy',
    label: 'Comedy',
  },
  {
    id: 'suspense',
    label: 'Suspense',
  },
  {
    id: 'non-fiction',
    label: 'Non-Fiction',
  },
  {
    id: 'poetry',
    label: 'Poetry',
  },
  {
    id: 'random',
    label: 'Random',
  },
  {
    id: 'romance',
    label: 'Romance',
  },
  {
    id: 'science-fiction',
    label: 'Science Fiction',
  },
  {
    id: 'historical-fiction',
    label: 'Historical Fiction'
  },
  {
    id: 'short-story',
    label: 'Short Story',
  },
  {
    id: 'teen-fiction',
    label: 'Teen Fiction',
  }
];