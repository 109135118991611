import { Avatar } from 'components/avatar'
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from 'components/dropdown'
import { SidebarLayout } from 'components/sidebar-layout'
import { Navbar, NavbarSection } from 'components/navbar'
import {
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarHeader,
  SidebarHeading,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
  SidebarSpacer,
} from 'components/sidebar'
import {
  ArrowRightStartOnRectangleIcon,
  Cog8ToothIcon,
  LightBulbIcon,
  ShieldCheckIcon,
  UserIcon,
} from '@heroicons/react/16/solid'
import {
  BellIcon,
  DocumentTextIcon,
  EnvelopeIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  StarIcon,
  MagnifyingGlassIcon,
  TrophyIcon,
  RocketLaunchIcon,
  ChevronDownIcon,
  BoltIcon,
} from '@heroicons/react/20/solid'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Logo from "assets/img/Quizini-Logo.svg";
import LegendiniLogo from "assets/img/Legendini-logo.png";
import useAuth from 'utils/hooks/useAuth'
import Progressbar from 'components/Progress/Progressbar'
import SmallStreakIndicator from 'components/Streak/SmallStreakIndicator'
import { Heading } from 'components/heading'
import useLogout from 'utils/hooks/useLogout'
import { useEffect, useState } from 'react'
import clsx from 'clsx'
import { ROLES } from 'utils/data/Constants'
import { PremiumBadge, ProBannerSmall } from 'components/Spark/Spark'
import { Button } from 'components/button'

export default function NewNewNavbar({children}) {
  const { auth } = useAuth();
  const location = useLocation();
  const logout = useLogout();
  const navigate = useNavigate();

  const menuItems = [
    {
      name: 'Explore',
      url: '/',
      icon: HomeIcon,
    },
    {
      name: 'Timeline',
      url: '/feed',
      icon: StarIcon,
      isNew: "Beta",
      isHidden: (!auth?.user?.roles.includes(ROLES.Premium) && !auth?.user?.roles.includes(ROLES.Admin) && !auth?.user?.roles.includes(ROLES.Tester)),
    },
    {
      name: 'Search',
      url: '/search',
      icon: MagnifyingGlassIcon,
      desktopOnly: true,
    },
    {
      name: 'Profile',
      url: '/user/'+auth?.user?.username,
      icon: UserIcon,
      isHidden: auth?.user ? false : true,
    },
    {
      name: 'Messages',
      url: '/messages',
      icon: EnvelopeIcon,
      desktopOnly: true,
      unreadCount: auth?.unreadMessageCount,
      isHidden: auth?.user ? false : true,
    },
    {
      name: 'Notifications',
      url: '/notifications',
      icon: BellIcon,
      desktopOnly: true,
      unreadCount: auth?.unreadNotificationCount,
      isHidden: auth?.user ? false : true,
    },
    {
      name: 'Leaderboard',
      url: '/leaderboard',
      icon: TrophyIcon,
    },
    {
      name: 'Challenges',
      url: '/challenges',
      icon: BoltIcon,
      // isNew: "Active",
    },
    // {
    //   name: 'Spark',
    //   url: '/spark',
    //   isSpark: true,
    // },
  ];

  const createMenuItems = [
    {
      name: 'My Quizzes',
      url: '/overview',
      icon: QuestionMarkCircleIcon,
    },
    {
      name: 'My Stories',
      url: '/mystories',
      icon: DocumentTextIcon,
    },
  ];

  const apiPostLogout = async () => {
    await logout();
    navigate('/');
  };

  return (
    <>
    <SidebarLayout
      navbar={
        <Navbar
          className={"justify-center"}
        >
          <NavbarSection>
            <Link to="/">
              <img
                className="h-8 w-auto"
                src={Logo}
                alt="Your Company"
              />
            </Link>
            <Heading
              id="navbar-title"
            >
            </Heading>
          </NavbarSection>
        </Navbar>
      }
      sidebar={
        <Sidebar>
          <SidebarHeader>
            {/* {auth?.user?.roles.includes(ROLES.Admin) ? (
              <Dropdown>
                <DropdownButton as={SidebarItem} className="w-full flex justify-between">
                  <img
                    className="h-8 ml-1 w-auto"
                    src={LegendiniLogo}
                    alt="Your Company"
                  />
                  <ChevronDownIcon className='float-end' />
                </DropdownButton>
                <DropdownMenu className="min-w-80 lg:min-w-64" anchor="bottom start">
                  <DropdownItem href="/" className={"hover:bg-gray-200"}>
                    <img
                      className="h-8 ml-1 w-auto"
                      src={Logo}
                      alt="Your Company"
                    />
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            ) : (
            )} */}
              <Link to="/">
                <img
                  className="h-8 ml-1 w-auto"
                  src={Logo}
                  alt="Your Company"
                />
              </Link>
          </SidebarHeader>
          <SidebarBody>
            <SidebarSection>
              {menuItems.map((item) => (
                !item.isHidden && (
                  <SidebarItem 
                    key={item.name} 
                    href={item.url}
                    current={item.url === location.pathname}
                    className={clsx(
                      item.desktopOnly ? 'hidden lg:block' : '',
                    )}
                  >
                    <item.icon />
                    <SidebarLabel>
                      {item.name}
                    </SidebarLabel>
                    {item.isNew && (
                      <span className="absolute right-3 ml-2 text-xs/5 font-semibold text-purple-700 bg-purple-100 px-1 rounded-full">
                        {item.isNew}
                      </span>
                    )}
                    {item.unreadCount > 0 && (
                      <UnreadIndicatorSidebar unreadCount={item.unreadCount} />
                    )}
                  </SidebarItem>
                )
              ))}
            </SidebarSection>

            {auth?.user && (
              <SidebarSection>
                <SidebarHeading>Create</SidebarHeading>
                {createMenuItems.map((item) => (
                  <SidebarItem 
                    key={item.name} 
                    href={item.url}
                    current={item.href === window.location.pathname}
                  >
                    <item.icon />
                    <SidebarLabel>{item.name}</SidebarLabel>
                  </SidebarItem>
                ))}
              </SidebarSection>
            )}
            <SidebarSpacer />
            <SidebarSection>
              <div className="px-2">
                <Progressbar />
              </div>
              {!auth?.user?.roles.includes(ROLES.Premium) && (
                <div className='mt-2 px-2'>
                  <ProBannerSmall />
                </div>
              )}
            </SidebarSection>
          </SidebarBody>
          {auth?.user ? (
          <SidebarFooter >
            <Dropdown>
              <DropdownButton as={SidebarItem} className="">
                <span className="flex w-full min-w-0 items-center gap-3">
                  <Avatar src={auth?.user?.img?.url} className="w-10 h-10" alt="" />
                  <span className="min-w-0 flex flex-col items-start">
                    <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">{auth?.user?.name ? auth?.user?.name : auth?.user?.username}</span>
                    {/* <span className="block truncate text-xs/.js5 font-normal text-zinc-500 dark:text-zinc-400">
                      @{auth?.user?.username}
                    </span> */}
                    {auth?.user?.roles.includes(ROLES.Premium) && (
                      <PremiumBadge className="ml-0" text="Spark Member" />
                    )}
                  </span>
                </span>
                <div className="mr-2">
                  <SmallStreakIndicator userStreak={auth?.user?.streak} />
                </div>
              </DropdownButton>
              <DropdownMenu className="min-w-64" anchor="top start">
                <DropdownItem href="/settings/general">
                  <Cog8ToothIcon />
                  <DropdownLabel>Settings</DropdownLabel>
                </DropdownItem>
                <DropdownDivider />
                <DropdownItem href="/updates">
                  <RocketLaunchIcon />
                  <DropdownLabel>Latest Updates</DropdownLabel>
                </DropdownItem>
                <DropdownItem href="/privacy">
                  <ShieldCheckIcon />
                  <DropdownLabel>Privacy policy</DropdownLabel>
                </DropdownItem>
                <DropdownItem href="/contact">
                  <LightBulbIcon />
                  <DropdownLabel>Share feedback</DropdownLabel>
                </DropdownItem>
                <DropdownDivider />
                <DropdownItem
                  onClick={apiPostLogout}
                >
                  <ArrowRightStartOnRectangleIcon />
                  <DropdownLabel>Sign out</DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </SidebarFooter>
          ) : (
            <SidebarFooter>
              <Button
                href="/login"
                plain
                className="whitespace-nowrap"
              >
                Log in
              </Button>
              <Button 
                href="/register"
                color="quizini"
                className="px-[20px] sm:px-[20px] whitespace-nowrap"
              >
                Sign up
              </Button>
            </SidebarFooter>
          )}
        </Sidebar>
      }
    >
      {children}
      <MobileBottomNav auth={auth} />
    </SidebarLayout>
    {/* <aside className="sticky top-8 hidden w-96 shrink-0 xl:block bg-gray-100">Right column area</aside> */}
    </>
  )
}


function getMobileMenuItems(auth) {
  let menuItems;
  if (auth?.user) {
    menuItems = [
      // {
      //   name: 'Explore',
      //   url: '/search',
      //   icon: MagnifyingGlassIcon,
      // },
      {
        name: 'Home',
        url: '/',
        icon: HomeIcon,
      },
      {
        name: 'Messages',
        url: '/messages',
        icon: EnvelopeIcon,
      },
      {
        name: 'Notifications',
        url: '/notifications',
        icon: BellIcon,
      },
      {
        name: 'Search',
        url: '/search',
        icon: MagnifyingGlassIcon,
      },
    ];
  } else {
    menuItems = [
      {
        name: 'Explore',
        url: '/search',
        icon: MagnifyingGlassIcon,
      },
      {
        name: 'Home',
        url: '/',
        icon: HomeIcon,
      },
      {
        name: 'Log in',
        url: '/login',
        icon: UserIcon,
      },
    ];
  }
  return menuItems;
}

function MobileBottomNav({ auth }) {
  const location = useLocation();
  const [isHidden, setIsHidden] = useState(false);

  const hiddenPages = ['/messages/'];

  useEffect(() => {
    if (hiddenPages.some((url) => location.pathname.includes(url))) {
      setIsHidden(true);
    } else {
      setIsHidden(false);
    }
  }, [location.pathname]);

  const menuItems = getMobileMenuItems(auth);
  return (
    <section
      id="bottom-navigation"
      className={clsx(
        'lg:hidden border-t border-gray-200 fixed inset-x-0 bottom-0 z-10 bg-white shadow transition-all duration-200 ease-in-out',
        isHidden ? ' hidden' : ' block'
      )}
    >
      <div id="tabs" className="flex justify-center">
        {menuItems.map((option, index) => {
          return (
            <Link
              key={index}
              to={option?.url}
              aria-label={'go to ' + option?.name}
              className={
                'w-full text-gray-600 focus:text-purple-700 hover:text-purple-700 text-center pt-2 pb-2 '
              }
            >
              <div className="flex justify-center relative">
                {option.name === 'Notifications' && (
                  <UnreadIndicator
                    unreadCount={auth?.unreadNotificationCount}
                    classNames="mx-auto left-6 -mt-2"
                  />
                )}
                {option.name === 'Messages' && (
                  <UnreadIndicator
                    unreadCount={auth?.unreadMessageCount}
                    classNames="mx-auto left-9 -mt-2"
                  />
                )}
                <option.icon className="w-7 h-7" />
              </div>
            </Link>
          );
        })}
      </div>
    </section>
  );
}


export function UnreadIndicator({unreadCount, classNames}){
  if (unreadCount > 0) {
    return(
      <span className={"w-3 h-3 bg-purple-600 ring-1 ring-white absolute top-2 right-3 rounded-full "+classNames}></span>
    )
  } else {
    return null;
  }
}

export function UnreadIndicatorSidebar({ unreadCount, classNames = "" }) {
  return unreadCount > 0 ? (
    <span
      className={`w-3 h-3 bg-purple-600 absolute right-3 rounded-full ${classNames}`}
    ></span>
  ) : null;
}