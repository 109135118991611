import { Outlet } from "react-router-dom";

// components
import Footer from "components/Footer/Footer.js";
import NewNewNavbar from "components/Navbar/NewNewNavbar";

export default function Layout() {
  return (
    <>
      <NewNewNavbar>
        <Outlet />
        <Footer />
      </NewNewNavbar>
    </>
  );
}
