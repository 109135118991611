import { useState, useEffect } from "react";
import API from "utils/api.js";
import { useParams } from "react-router-dom";
import { Container } from "components/Container/Container.js";
import StoryCard from "views/Dashboard/Stories/StoryCard.js";
import { SecondaryHeader } from "components/pageHeader";
import { Heading } from "components/heading";

export default function StoryTags(props) {
  const [stories, setStories] = useState([]);
  const { tag } = useParams();

  useEffect(() => {
    fetchStoriesByTag();
  }, [tag]);

  function fetchStoriesByTag() {
    API.post("/api/story/getByTag", { tag: tag }).then(function (res) {
      if (res.data != null && res.data.success !== false) {
        setStories(res.data.stories);
      } else {
        console.log("error");
      }
    });
  }

  return (
    <>
    <SecondaryHeader>
      <Heading>Stories for tag - {tag}</Heading>
    </SecondaryHeader>
    <Container className="mb-20">
      <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-4">
        {stories.map((story, index) => (
          <StoryCard key={index} story={story} />
        ))}
      </div>
    </Container>
    </>
  );
}
